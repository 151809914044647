import { configuredAxios } from './configuredAxios';
import { appConfig } from 'app/app.config';

export const attachmentsAPI = {
  download: (attachmentId: string): Promise<Blob> => {
    return configuredAxios
      .get(`${appConfig.attachmentsEndpoint}/${attachmentId}`, {
        responseType: 'blob',
      })
      .then((result) => new Blob(result.data))
      .catch((error) => error);
  },
};
