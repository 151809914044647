import { NCADto } from 'api/genTypes/dto';
import { DataTable } from 'components/DataTable/DataTable';
import { DataTableColumn } from 'components/DataTable/DataTable.types';
import { useNCAControlsMultiSelectStore } from 'pages/nca/components/NCAControlsMultiSelect/NCAControlsMultiSelect.store';
import { isNCASelectable } from 'pages/nca/components/NCAControlsMultiSelect/NCAControlsMultiSelect.utils';
import styled from 'styled-components';

const StyledDataTableForNCAList = styled(DataTable)`
  height: 50%;
  background-color: yellow;

  table {
    display: none;
  }
`;

/** NCAListTable is responsible for the display-only of the NCA List data.
 * Querying and filtering logic is delegated to other components.
 */
export function NCAListTable({
  data,
  isLoading,
  error,
  columns,
}: {
  data: NCADto[] | undefined;
  isLoading: boolean;
  error: unknown | undefined;
  columns: DataTableColumn<NCADto>[];
}) {
  // We use a global store for the multiselect, so that it is available outside the NCAList as well.
  const ncaMultiSelectStore = useNCAControlsMultiSelectStore();

  return (
    <StyledDataTableForNCAList<NCADto>
      columns={columns}
      data={data}
      isLoading={isLoading}
      error={error}
      idField="nonConformityAdviceId"
      onSelect={(e) => ncaMultiSelectStore.setState(e)}
      withFiltering
      withSelecting
      // We use this to override the local selection state of the DataTable with our global NCA MultiSelect store.
      selectionValues={ncaMultiSelectStore.selectedItems}
      validateSelection={isNCASelectable}
      withServerData
    />
  );
}
