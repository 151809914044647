import { msalInstance } from 'app/app.providers';

import { InteractionRequiredAuthError } from '@azure/msal-browser';

// TODO: acquireTokenSilent seems to fail with a 400 bad request.
// It may be due to this issue: https://stackoverflow.com/questions/46100272/azure-portal-bad-request-request-too-long
// Could be the same as is described here: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/1188
// And here: https://stackoverflow.com/questions/59633078/msal-js-aad-b2c-multi-factor-authentication-400-bad-request-request-header-to
//
// This one seems to describe the issue even more accurately compared to our console output: https://stackoverflow.com/questions/49831045/msal-js-acquiretokensilent-on-azure-ad-b2c-with-microsoft-account-login-live-co
//
// This one seems quite interesting: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/1199
// If true, it could be that we did not configure the redirect URLs correctly in Azure Portal to also allow the token redirect URL.

export async function acquireAccessToken(apiScopes: string[]) {
  try {
    const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = msalInstance.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
      window.location.href = '/';
      return;
    }
    const request = {
      scopes: apiScopes,
      account: activeAccount || accounts[0],
    };

    const authResult = await msalInstance.acquireTokenSilent(request).catch(async (error: any) => {
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return await msalInstance.acquireTokenPopup(request).catch((error) => {
          throw new Error(error);
        });
      }
    });

    return authResult.accessToken;
  } catch {
    // If we cannot refresh the token, then we can try to redirect the user to login again.
    // msalInstance.loginRedirect();
  }
}
