import Table, { TableHeader, TableBody } from '@ingka/table';
import Checkbox from '@ingka/checkbox';
import { DataTableEntity, DataTableProps } from './DataTable.types';
import { DataTableCell } from './components/DataTableCell/DataTableCell';
import { DataContentWrapper } from 'components/common/DataWrapper';
import styled from 'styled-components';
import { DataTableColumnHeader } from './components/DataTableColumnHeader/DataTableColumnHeader';
import { isNCASelectable } from 'pages/nca/components/NCAControlsMultiSelect/NCAControlsMultiSelect.utils';
import { useDataTable } from './hooks/useDataTable';

const Th = styled.th`
  font-weight: bold;
  white-space: nowrap;
`;
const Td = styled.td`
  white-space: nowrap;
`;

const TdInner = styled.div`
  padding-left: 1rem;
`;

const Tr = styled.tr`
  margin: 0 1rem;
`;

/** DataTable represents a generic data table which renders
 * the provided data in the browser according to the columns provided. */
export function DataTable<T extends DataTableEntity>({
  columns,
  data,
  isLoading,
  error,
  idField,
  withFiltering,
  withSorting,
  withSelecting,
  selectionValues,
  validateSelection,
  onSelect,
}: DataTableProps<T>) {
  const {
    columnsToRender,
    shouldRenderTableRows,
    visibleItems,
    areAllSelectableVisibleItemsSelected,
    selectedItems,
    handleOnSelect,
    handleOnSelectAll,
  } = useDataTable({
    columns,
    data,
    isLoading,
    error,
    idField,
    withFiltering,
    withSorting,
    withSelecting,
    selectionValues,
    validateSelection,
    onSelect,
  });

  return (
    <>
      <Table fullWidth style={{ height: '100%' }}>
        {/* Table Header */}
        <TableHeader>
          {/* The header only has a single row */}
          <Tr>
            {columnsToRender.map((c) => {
              if (c.columnType === 'checkbox') {
                return (
                  <Th key={`data-table-header-column-${c.columnId}`}>
                    <Checkbox
                      id={`data-table-checkbox`}
                      data-testid="data-table-checkbox"
                      value={''}
                      checked={areAllSelectableVisibleItemsSelected}
                      onChange={(_) => handleOnSelectAll()}
                      disabled={isLoading || visibleItems.length === 0}
                    />
                  </Th>
                );
              }

              return (
                // One header cell for each column to render
                <Th key={`data-table-header-column-${c.columnId}`}>
                  <DataTableColumnHeader
                    column={c}
                    withFiltering={withFiltering}
                    withSorting={withSorting}
                    withSelecting={withSelecting}
                  />
                </Th>
              );
            })}
          </Tr>
        </TableHeader>

        {/* Table Body */}
        <TableBody>
          {/* Only render the data depending on this condition. If false, we render no rows. */}
          {shouldRenderTableRows &&
            visibleItems.map((dataItem: T, index: number) => (
              // Render each row of the data
              <Tr
                key={`data-table-row-${idField ? dataItem[idField] : index}`}
                data-testid="data-table-row"
              >
                {/* For each row, render the corresponding field values as columns */}
                {columnsToRender.map((c) => (
                  <Td
                    key={`data-table-row-${idField ? dataItem[idField] : index}-column-${
                      c.columnId
                    }`}
                  >
                    <TdInner>
                      <DataTableCell<T>
                        column={c}
                        dataItem={dataItem}
                        idField={idField}
                        onSelect={handleOnSelect}
                        isSelectable={isNCASelectable(selectedItems, dataItem)}
                        isSelected={
                          // idField must be set, otherwise we have no id field to compare to.
                          idField && selectedItems.has(dataItem[idField])
                        }
                        isTable
                      />
                    </TdInner>
                  </Td>
                ))}
              </Tr>
            ))}
        </TableBody>
      </Table>

      {/* Render loading, data, and error alerts outside the table body to ensure better formatting */}
      <DataContentWrapper data={visibleItems} isLoading={isLoading} error={error} />
    </>
  );
}
