import Text from '@ingka/text';
import { AttachmentDto, GetOneNCAResponse } from 'api/genTypes/dto';
import { useTranslate } from 'hooks/useTranslate';
import { DataTable } from 'components/DataTable/DataTable';
import { attachmentsAPI } from 'api/attachmentsApi';
import Button from '@ingka/button';
import { useDownloadFile } from 'hooks/useDownloadFile';

export function NCADetailsAttachmentsItem({ attachment }: { attachment: AttachmentDto }) {
  const { isLoading, download } = useDownloadFile(attachment.attachmentId, attachmentsAPI.download);

  return (
    <div>
      <Button
        onClick={download}
        disabled={isLoading}
        loading={isLoading}
        type="tertiary"
        size="xsmall"
      >
        <div>
          <Text>{attachment.filename}</Text>
        </div>
      </Button>
    </div>
  );
}

export function NCADetailsAttachments({ nca }: { nca: GetOneNCAResponse }) {
  const t = useTranslate();

  // If there are no attachments,
  // render a message to the user that there are no attachments
  if (!nca.attachments || nca.attachments.length === 0) {
    return <Text>{t('attachmentsEmptyMessage')}</Text>;
  }

  return (
    <DataTable<AttachmentDto>
      data={nca.attachments}
      columns={[
        {
          columnId: 'filename',
          columnType: 'string',
          render: (attachment: AttachmentDto) => (
            <NCADetailsAttachmentsItem attachment={attachment} />
          ),
        },
        {
          columnId: 'filetype',
          columnType: 'string',
        },
      ]}
    />
  );
}
