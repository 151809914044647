import Button from '@ingka/button';
import Select, { Option } from '@ingka/select';
import { RowCentered, Stack } from 'components/layout';
import { useSearchParams } from 'react-router-dom';

export interface NCAListPaginationProps {
  totalPages: number;
  loading?: boolean;
}

export function calculateValidPage(newPage: number, totalPages: number): number {
  if (newPage < 1) {
    return 1;
  } else if (newPage > totalPages) {
    return totalPages;
  }

  return newPage;
}

function getAndParsePageFromSearchParams(searchParams: URLSearchParams): number {
  const searchParamsPage = searchParams.get('page') || '1';
  return parseInt(searchParamsPage);
}

function initPage(searchParams: URLSearchParams, totalPages: number): number {
  const page: number = getAndParsePageFromSearchParams(searchParams);
  return calculateValidPage(page, totalPages);
}

export function useNCAListPagination(totalPages: number) {
  const [searchParams, setSearchParams] = useSearchParams();
  const page: number = initPage(searchParams, totalPages);

  const handlePageNavigation = (newPage: number): void => {
    const validatedNewPage = calculateValidPage(newPage, totalPages);
    const newSearchParams = new URLSearchParams(searchParams);

    newSearchParams.set('page', validatedNewPage.toString());
    setSearchParams(newSearchParams);
  };

  const handlePageIncrement = (): void => handlePageNavigation(page + 1);
  const handlePageDecrement = (): void => handlePageNavigation(page - 1);

  return {
    page,
    totalPages,
    handlePageIncrement,
    handlePageDecrement,
    handlePageNavigation,
  };
}

export function NCAListPaginationUI({
  totalPages,
  page,
  handlePageIncrement,
  handlePageDecrement,
  handlePageNavigation,
  loading,
}: ReturnType<typeof useNCAListPagination> & { loading: boolean }) {
  return (
    <Stack>
      <RowCentered>
        {/* Previous page */}
        <Button onClick={handlePageDecrement} size="small" disabled={page === 1 || loading}>
          {'<'}
        </Button>

        {/* Current page and page-picker */}
        <Select
          id="pagination-select"
          value={page.toString()}
          onChange={(e) => handlePageNavigation(parseInt(e.target.value))}
          style={{ width: 'auto' }}
          hintText={''}
          disabled={loading}
        >
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNumber) => (
            <Option
              key={pageNumber}
              id={pageNumber.toString()}
              value={pageNumber.toString()}
              name={`${pageNumber} of ${totalPages}`}
            />
          ))}
        </Select>

        {/* Next page */}
        <Button
          onClick={handlePageIncrement}
          size="small"
          disabled={page === totalPages || loading}
        >
          {'>'}
        </Button>
      </RowCentered>
    </Stack>
  );
}

export function NCAListPagination({ totalPages, loading }: NCAListPaginationProps) {
  const { page, handlePageIncrement, handlePageDecrement, handlePageNavigation } =
    useNCAListPagination(totalPages);

  return (
    <NCAListPaginationUI
      totalPages={totalPages}
      page={page}
      handlePageIncrement={handlePageIncrement}
      handlePageDecrement={handlePageDecrement}
      handlePageNavigation={handlePageNavigation}
      loading={loading}
    />
  );
}
