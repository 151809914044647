import InlineMessage from '@ingka/inline-message';
import { useTranslate } from 'hooks/useTranslate';
import styled from 'styled-components';

export const AlertContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export function NoData() {
  const t = useTranslate();

  return (
    <AlertContainer>
      <InlineMessage
        body={t('noDataMessage')}
        link=""
        linkLabel=""
        title={t('noDataTitle')}
        variant="informative"
      />
    </AlertContainer>
  );
}

export function UnexpectedError() {
  const t = useTranslate();

  return (
    <AlertContainer>
      <InlineMessage
        body={t('unexpectedErrorMessage')}
        link=""
        linkLabel=""
        title={t('unexpectedErrorTitle')}
        variant="negative"
      />
    </AlertContainer>
  );
}

export function ErrorAlert({ message, title }: { message: string; title: string }) {
  return (
    <AlertContainer>
      <InlineMessage body={message} link="" linkLabel="" title={title} variant="negative" />
    </AlertContainer>
  );
}

export function InfoAlert({ message, title }: { message: string; title: string }) {
  return (
    <AlertContainer>
      <InlineMessage body={message} link="" linkLabel="" title={title} variant="informative" />
    </AlertContainer>
  );
}
