import { filterParenthesisInfo } from '../utils/filterParenthesisInfo';
import { getUserInitial } from '../utils/getUserInitial';
import { useSignOut } from './useSignOut';
import { useUserAccount } from './useUserAccount';

export function useAccountInfo() {
  const account = useUserAccount();
  const signOut = useSignOut(account);

  const userNameFull: string = account.name;
  const userName: string = filterParenthesisInfo(account.name);
  const userInitials: string = getUserInitial(account.name);

  return {
    account,
    userInitials,
    userName,
    userNameFull,
    signOut,
  };
}
