import { Row, Stack } from 'components/layout';
import { useNCAControlsMultiSelect } from './hooks/useNCAControlsMultiSelect';
import { Subsection } from 'components/common/Subsection';
import { DataDetails } from 'components/DataDetails/DataDetails';
import { DataTable } from 'components/DataTable/DataTable';
import { NCADto } from 'api/genTypes/dto';
import styled from 'styled-components';
import Modal, { ModalHeader, Theatre } from '@ingka/modal';
import Text from '@ingka/text';
import { useTranslate } from 'hooks/useTranslate';
import Button from '@ingka/button';
import { DataTableColumn } from 'components/DataTable/DataTable.types';
import { NCAControlsResolutionComment } from '../NCAControls/components/NCAControlsResolutionComment/NCAControlsResolutionComment';
import { NCAControlsActionSelect } from '../NCAControls/components/NCAControlsActionSelect/NCAControlsActionSelect';
import { useNCAControls } from '../NCAControls/hooks/useNCAControls';
import { useEffect } from 'react';
import { NCAControlsSubmissionNotifications } from '../NCAControls/components/NCAControlsSubmissionNotifications/NCAControlsSubmissionNotifications';

const MultiSelectBanner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: auto;
`;

const StyledModalStack = styled(Stack)`
  padding: 1rem;
`;

interface NCAControlsMultiSelectOverviewProps {
  overviewColumns: DataTableColumn<any>[];
  nbrOfSelectedNCAs: number;
  sumOfAmountForSelectedNCAs: number;
}

function NCAControlsMultiSelectOverview({
  overviewColumns,
  nbrOfSelectedNCAs,
  sumOfAmountForSelectedNCAs,
}: NCAControlsMultiSelectOverviewProps) {
  return (
    <DataDetails<any>
      columns={overviewColumns}
      data={{
        ncaMultiSelectCount: nbrOfSelectedNCAs,
        ncaMultiSelectSum: sumOfAmountForSelectedNCAs,
      }}
    />
  );
}

export function NCAControlsMultiSelect() {
  const {
    nbrOfSelectedNCAs,
    sumOfAmountForSelectedNCAs,
    selectedNCAs,
    selectedNCAsColumns,
    overviewColumns,
    isMultiSelectPanelVisible,
    deselectAll,
    disclosure,
    onNotificationSubmission,
  } = useNCAControlsMultiSelect();
  const t = useTranslate();

  const {
    isAnyClaimActionPossible,
    resetFormState,
    actionState,
    reasonCodeState,
    causingPartyState,
    resolutionCommentState,
    submittingState,
  } = useNCAControls(Array.from(selectedNCAs.values()), onNotificationSubmission, true);

  // Reset Actions Options when NCA selection changes
  // TODO: Test Hook
  useEffect(() => {
    resetFormState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNCAs]);

  // TODO: test this is triggered by modal close
  const onModalClose = () => {
    disclosure.close();
    submittingState.resetNcaResponse();
  };

  return (
    <>
      {/* Full screen modal */}
      <Modal
        handleCloseBtn={onModalClose}
        visible={disclosure.isOpen}
        data-testid={'multiSelect-modal-testid'}
        escapable
      >
        <Theatre
          header={
            <ModalHeader>
              <Text headingSize="l" tagName="h3">
                {t('ncaMultiSelectHeader')}
              </Text>
            </ModalHeader>
          }
          title={`${t('ncaMultiSelectHeader')}`}
        >
          <StyledModalStack>
            <Subsection header={t('ncaMultiSelectListOfClaims')}>
              <DataTable<NCADto>
                data={Array.from(selectedNCAs.values())}
                columns={selectedNCAsColumns}
              />
            </Subsection>
            <Subsection header={t('ncaMultiSelectSummary')}>
              <NCAControlsMultiSelectOverview
                nbrOfSelectedNCAs={nbrOfSelectedNCAs}
                sumOfAmountForSelectedNCAs={sumOfAmountForSelectedNCAs}
                overviewColumns={overviewColumns}
              />
            </Subsection>
            <Subsection header={'Test Action Buttons'}>
              {/* Resolution Comment */}
              <NCAControlsResolutionComment resolutionCommentState={resolutionCommentState} />

              {/* Claim Action */}
              <NCAControlsActionSelect
                isAnyClaimActionPossible={isAnyClaimActionPossible}
                actionState={actionState}
                reasonCodeState={reasonCodeState}
                causingPartyState={causingPartyState}
                submittingState={submittingState}
                onDeselect={() => {
                  deselectAll();
                  disclosure.close();
                }}
              />
              <NCAControlsSubmissionNotifications submittingState={submittingState} />
            </Subsection>
          </StyledModalStack>
        </Theatre>
      </Modal>

      {/* Panel that is shown below the table in the NCAList. */}
      {isMultiSelectPanelVisible && (
        <MultiSelectBanner>
          <NCAControlsMultiSelectOverview
            nbrOfSelectedNCAs={nbrOfSelectedNCAs}
            sumOfAmountForSelectedNCAs={sumOfAmountForSelectedNCAs}
            overviewColumns={overviewColumns}
          />
          <Row>
            <Button type="secondary" onClick={() => deselectAll()}>
              {t('ncaMultiSelectButtonDeselect')}
            </Button>
            <Button
              type="primary"
              onClick={() => disclosure.open()}
              data-testid={'multi-select-button-testId'}
            >
              {t('ncaMultiSelectButton')}
            </Button>
          </Row>
        </MultiSelectBanner>
      )}
    </>
  );
}
